import { Command, LinkParams, Placement } from './types';
import qs from 'qs';

/**
 * Executes a function when the DOM is ready.
 *
 * @param fn - The function to execute.
 */
export const ready = (fn: () => void) => {
  if (document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
};

/**
 * Cast raw function arguments to a command.
 *
 * @param rawArgs - command arguments.
 */
export const castCommand = (rawArgs: any[]): Command => {
  const [name, ...args] = rawArgs;
  return { name, args } as Command;
};

/**
 * Builds an iframe URL with optional link parameters.
 */
export const buildFrameUrl = (
  url: URL,
  instance: string,
  placement: Placement,
  params: Partial<LinkParams>
): URL => {
  const metadataParams = new URLSearchParams(
    params.metadata
      ? qs.stringify(
          { metadata: params.metadata || {} },
          { arrayFormat: 'brackets' }
        )
      : ''
  );

  const questionsParams = new URLSearchParams(
    params.questions
      ? qs.stringify(
          { questions: params.questions || {} },
          { arrayFormat: 'brackets' }
        )
      : ''
  );

  questionsParams.forEach((value, key) => {
    url.searchParams.append(key, value);
  });

  metadataParams.forEach((value, key) => {
    url.searchParams.append(key, value);
  });

  url.searchParams.append('instance', instance);
  url.searchParams.append('context', 'embed');
  url.searchParams.append('placement', placement);
  url.searchParams.append(
    'show_view_changer',
    params.showViewChanger ? 'true' : 'false'
  );
  if (params.theme) url.searchParams.append('theme', params.theme);
  if (params.email) url.searchParams.append('email', params.email);
  if (params.view) url.searchParams.append('view', params.view);
  if (params.hideAvatar) url.searchParams.append('hide_avatar', 'true');
  if (params.hideBanner) url.searchParams.append('hide_banner', 'true');

  if (params.displayName) {
    url.searchParams.append('display_name', params.displayName);
  }

  if (params.phone) {
    url.searchParams.append('phone', params.phone.toString());
  }

  url.searchParams.append('parent_url', document.location.href);

  return url;
};

/**
 * Builds an iframe URL from a path and optional link parameters.
 */
export const buildFrameUrlFromPath = (
  origin: string,
  path: string,
  instance: string,
  placement: Placement,
  params: Partial<LinkParams>
): URL => {
  const url = new URL(`${origin}/${path}`);
  return buildFrameUrl(url, instance, placement, params);
};

/**
 * Generates a random string.
 *
 * @param length - The length of the string.
 * @returns A random string.
 */
export const randomString = (length = 8) => {
  return Math.random().toString(16).substr(2, length);
};

/**
 * Builds a class name string (and excludes falsey items).
 *
 * @param classes - a list of classes.
 * @returns A class name string.
 */
export const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ');
};
