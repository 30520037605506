import { castCommand, ready } from './util';
import { render, h } from 'preact';
import { Popover } from './components/Popover';
import {
  Config,
  InlineArgs,
  LinkParams,
  OpenArgs,
  WidgetConfig,
} from './types';
import deepmerge from 'deepmerge';

import './styles.css';
import { Inline } from './components/Inline';

/** @jsx h */

declare global {
  interface Window {
    SavvyCal: API;
  }
}

interface API {
  (...args: any[]): void;
  q?: IArguments[];
  events?: CustomEvent[];
}

window.SavvyCal =
  window.SavvyCal ||
  function () {
    (window.SavvyCal.q = window.SavvyCal.q || []).push(arguments);
  };

const defaults: Config = {
  widget: {
    backgroundColor: '#FFCA00',
    delay: 1000,
    displayName: null,
    email: null,
    enabled: true,
    hideAvatar: false,
    hideBanner: false,
    icon: 'calendar',
    metadata: {},
    position: 'bottom-right',
    prompt: 'Schedule a time',
    questions: {},
    showViewChanger: false,
    textColor: '#141E2F',
    theme: 'light',
    zIndex: 10000,
  },
  origin: 'https://savvycal.com',
};

let isInitialized = false;
let config: Config;

const ensureInitialized = () => {
  if (isInitialized) return;
  init({});
};

const dispatchEvent = (event: CustomEvent) => {
  window.SavvyCal.events = window.SavvyCal.events || [];
  window.SavvyCal.events.push(event);
  window.dispatchEvent(event);
};

const init = (partialConfig: Partial<Config> | undefined) => {
  config = deepmerge(defaults, partialConfig || {});

  const popoverContainer = document.createElement('div');
  popoverContainer.setAttribute('id', 'sc-embed');
  document.body.appendChild(popoverContainer);
  render(<Popover config={config} />, popoverContainer);

  const messageListener = (ev: MessageEvent) => {
    if (ev.origin !== config.origin) return;
    let detail: string | undefined = undefined;
    let props = ev.data?.props;

    if (typeof props === 'string') {
      detail = JSON.parse(ev.data.props);
    } else {
      detail = props;
    }

    switch (ev.data?.event) {
      case 'redirect':
        if (window.top) {
          window.top.location.href = ev.data.url;
        } else {
          window.location.href = ev.data.url;
        }

        return;

      case 'scheduled':
        window.dispatchEvent(new CustomEvent('savvycal.scheduled', { detail }));
        return;
    }
  };

  window.addEventListener('message', messageListener, false);
  isInitialized = true;
};

const open = (args: OpenArgs) => {
  if (!args.link) {
    console.error(
      '[SavvyCal] The `link` option is required when opening a link'
    );
    return;
  }

  const detail = JSON.stringify(args);
  const openEvent = new CustomEvent('savvycal.priv.open', { detail });
  dispatchEvent(openEvent);
};

const renderInline = (args: Partial<InlineArgs>) => {
  if (!args.link) {
    console.error(
      '[SavvyCal] The `link` option is required when rendering a link inline'
    );
    return;
  }

  if (!args.selector) {
    console.error(
      '[SavvyCal] The `selector` option is required when rendering a link inline'
    );
    return;
  }

  const inlineContainer = document.querySelector(args.selector);

  if (!inlineContainer) {
    console.error(
      '[SavvyCal] Element for rendering inline was not found',
      args.selector
    );
    return;
  }

  const params: Partial<LinkParams> = {
    displayName: args.displayName,
    email: args.email,
    hideAvatar: args.hideAvatar,
    hideBanner: args.hideBanner,
    metadata: args.metadata,
    phone: args.phone,
    questions: args.questions,
    showViewChanger: args.showViewChanger,
    theme: args.theme,
    view: args.view,
  };

  render(
    <Inline config={config} link={args.link} params={params} />,
    inlineContainer
  );
};

const updateWidget = (config: Partial<WidgetConfig>) => {
  const detail = JSON.stringify(config);
  const event = new CustomEvent('savvycal.priv.updateWidget', { detail });
  dispatchEvent(event);
};

const handler = (rawArgs: any[]): void => {
  const command = castCommand(rawArgs);

  switch (command.name) {
    case 'init':
      if (isInitialized) return;
      init(command.args[0]);
      break;

    case 'inline':
      ensureInitialized();
      renderInline(command.args[0]);
      break;

    case 'open':
      ensureInitialized();
      open(command.args[0]);
      break;

    case 'widget':
      ensureInitialized();
      updateWidget(command.args[0]);
      break;
  }
};

ready(() => {
  const queue = window.SavvyCal.q || [];
  window.SavvyCal = (...args) => handler(args);
  queue.forEach((args) => {
    window.SavvyCal.apply(null, Array.from(args));
  });
});
