import { h } from 'preact';

/** @jsx h */

export const Spinner = () => {
  return (
    <svg
      width="38"
      height="38"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
    >
      <g
        transform="translate(1 1)"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
      >
        <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
        <path d="M36 18c0-9.94-8.06-18-18-18"></path>
      </g>
    </svg>
  );
};
