import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { Config, LinkParams } from '../types';
import { buildFrameUrlFromPath, randomString } from '../util';

/** @jsx h */

interface Props {
  config: Config;
  link: string;
  params: Partial<LinkParams>;
}

export const Inline = ({ config, link, params }: Props) => {
  const [instance] = useState(randomString());
  const [desiredMinHeight, setDesiredMinHeight] = useState<number | 'none'>(
    'none'
  );
  const [maxHeight, setMaxHeight] = useState<number | 'none'>('none');
  const [maxWidth, setMaxWidth] = useState<number | 'none'>('none');
  const [view, setView] = useState(null);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [isNarrow, setIsNarrow] = useState(false);

  const debounce = (func: () => void, delay: number) => {
    let timerId: NodeJS.Timeout;

    return () => {
      clearTimeout(timerId);

      timerId = setTimeout(() => func(), delay);
    };
  };

  const address = buildFrameUrlFromPath(
    config.origin,
    link,
    instance,
    'inline',
    params
  );
  address.searchParams.append('inline', 'true');

  // Listen for resize events coming from the iframe
  useEffect(() => {
    const messageListener = (ev: MessageEvent) => {
      if (ev.origin !== config.origin) return;
      const data = ev.data;

      if (data.instance !== instance) return;

      switch (data.event) {
        case 'resized':
          // This data comes from the booking page and tells us information about what is being shown
          setDesiredMinHeight(data.minHeight);
          setMaxHeight(data.maxHeight);
          setMaxWidth(data.maxWidth);
          setView(data.view);
          setIsNarrow(data.isNarrow);

          return;
      }
    };

    const resizeListener = debounce(
      () => setWindowHeight(window.innerHeight),
      500
    );

    window.addEventListener('message', messageListener, false);
    window.addEventListener('resize', resizeListener, false);
    return () => {
      window.removeEventListener('message', messageListener);
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  let minHeight = desiredMinHeight;

  if ((view == 'week' || view == 'rolling') && !isNarrow) {
    // Set the min height to the smaller of either the window or the desired min height from the event, but never go so small that things look terrible
    minHeight = Math.max(
      Math.min(
        ...[
          ...(desiredMinHeight == 'none' ? [] : [desiredMinHeight]),
          windowHeight - 20,
        ]
      ),
      480
    );
  }

  return (
    <iframe
      title={instance}
      src={address.href}
      style={{
        border: 0,
        borderRadius: '12px',
        width: '100%',
        minHeight: `${minHeight}px`,
        maxHeight: maxHeight === 'none' ? 'none' : `${maxHeight}px`,
        boxShadow: 'rgba(0, 0, 0, 0.10) 0px 0px 0px 1px',
        maxWidth: maxWidth === 'none' ? 'none' : `${maxWidth}px`,
        // Center the frame only if we have a maximum width set
        ...(maxWidth !== 'none' ? { margin: '0 auto' } : {}),
        display: 'block',
      }}
    ></iframe>
  );
};
