import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { Transition } from '@headlessui/react';
import {
  Config,
  LinkParams,
  OpenArgs,
  Theme,
  View,
  WidgetConfig,
} from '../types';
import { buildFrameUrl, buildFrameUrlFromPath, classNames } from '../util';
import { CalendarIcon } from './CalendarIcon';
import { XIcon } from './XIcon';
import deepmerge from 'deepmerge';
import { Spinner } from './Spinner';

/** @jsx h */

const isDev = false;

interface Props {
  config: Config;
}

export const Popover = ({ config: initialConfig }: Props) => {
  const [config, setConfig] = useState(initialConfig);
  const [address, setAddress] = useState<URL | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isWidgetDelayed, setIsWidgetDelayed] = useState(true);
  const [theme, setTheme] = useState(config.widget.theme);
  const [maxWidth, setMaxWidth] = useState<number | 'none'>('none');
  const [maxHeight, setMaxHeight] = useState<number | 'none'>('none');

  const isWidgetEnabled = config.widget.enabled && !!config.widget.link;
  const showWidgetPrompt = isWidgetEnabled && !!config.widget.prompt && !isOpen;
  const zIndex = config.widget.zIndex;

  // Function for updating the address and re-setting loaded state
  const updateAddress = (newAddress: URL) => {
    setAddress((prev) => {
      if (prev && prev.href === newAddress.href) return prev;
      setIsLoaded(false);
      return newAddress;
    });
  };

  // Delay the widget appearing
  useEffect(() => {
    setTimeout(() => {
      setIsWidgetDelayed(false);
    }, config.widget.delay);
  }, [config.widget.delay]);

  // Listen for link clicks
  useEffect(() => {
    const handler = (e: MouseEvent) => {
      const target = e.target as Element;
      const anchor = target && target.closest('a');
      if (!anchor) return;

      if (anchor.getAttribute('data-savvycal-embed') === null) return;

      const href = anchor.getAttribute('href');
      if (href === null) return;

      const url = new URL(href);
      if (url.hostname !== 'savvycal.com' && url.origin !== config.origin)
        return;

      e.preventDefault();
      e.stopPropagation();
      if (isDev) console.log(`${url.href} opened`);

      const effectiveTheme =
        (anchor.getAttribute('data-theme') as Theme) || config.widget.theme;

      const effectiveView =
        (anchor.getAttribute('data-view') as View) || config.widget.view;

      const params: Partial<LinkParams> = {
        email: anchor.getAttribute('data-email'),
        displayName: anchor.getAttribute('data-display-name'),
        hideAvatar: anchor.getAttribute('data-hide-avatar') === 'true',
        hideBanner: anchor.getAttribute('data-hide-banner') === 'true',
        phone: anchor.getAttribute('data-phone'),
        theme: effectiveTheme,
        view: effectiveView,
      };

      setTheme(effectiveTheme);
      setIsOpen(true);
      updateAddress(buildFrameUrl(url, 'popover', 'popover', params));
    };

    document.body.addEventListener('click', handler);
    return () => document.body.removeEventListener('click', handler);
  }, []);

  // Listen for iframe messages
  useEffect(() => {
    const handler = (ev: MessageEvent) => {
      if (ev.origin !== config.origin) return;
      if (ev.data?.instance !== 'popover') return;
      const data = ev.data;

      switch (data?.event) {
        case 'loaded':
          setIsLoaded(true);
          return;

        case 'resized':
          setMaxWidth(data.maxWidth);
          setMaxHeight(data.maxHeight);
          return;

        case 'escaped':
          setIsOpen(false);
          return;
      }
    };

    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  }, []);

  // Listen for keystokes
  useEffect(() => {
    const onKeydown = (ev: KeyboardEvent) => {
      if (ev.key === 'Escape') {
        setIsOpen(false);
      }
    };

    document.addEventListener('keydown', onKeydown);
    return () => document.removeEventListener('keydown', onKeydown);
  }, []);

  const openHandler = ((ev: CustomEvent<string>) => {
    const {
      link,
      email,
      displayName,
      hideAvatar,
      hideBanner,
      phone,
      metadata,
      questions,
      theme = config.widget.theme,
      view,
    } = JSON.parse(ev.detail) as Partial<OpenArgs>;

    if (!link) {
      console.error('[SavvyCal] link is required');
      return;
    }

    const params: Partial<LinkParams> = {
      email,
      displayName,
      hideAvatar,
      hideBanner,
      phone,
      metadata,
      questions,
      theme,
      view,
    };

    const newAddress = buildFrameUrlFromPath(
      config.origin,
      link,
      'popover',
      'popover',
      params
    );

    setTheme(theme);
    setIsOpen(true);
    updateAddress(newAddress);
  }) as EventListener;

  const updateWidgetHandler = ((ev: CustomEvent<string>) => {
    const updates = JSON.parse(ev.detail) as Partial<WidgetConfig>;

    setConfig((prev) => {
      return { ...prev, widget: deepmerge(prev.widget, updates) };
    });

    if (updates.theme) setTheme(updates.theme);
  }) as EventListener;

  // Listen for open events
  useEffect(() => {
    window.addEventListener('savvycal.priv.open', openHandler);
    return () => window.removeEventListener('savvycal.priv.open', openHandler);
  }, [openHandler]);

  // Listen for update widget config events
  useEffect(() => {
    window.addEventListener('savvycal.priv.updateWidget', updateWidgetHandler);
    return () =>
      window.removeEventListener(
        'savvycal.priv.updateWidget',
        updateWidgetHandler
      );
  }, [updateWidgetHandler]);

  // Re-dispatch queued events
  useEffect(() => {
    const events = window.SavvyCal.events || [];
    events.forEach((event) => window.dispatchEvent(event));
  }, []);

  const hide = () => {
    setIsOpen(false);
  };

  const showModal = !!address && !!isOpen;

  return (
    <div className="antialiased">
      <Transition
        show={showModal}
        className="fixed inset-0 overflow-y-auto"
        style={{ zIndex }}
        unmount={false}
      >
        <div className="flex flex-col min-h-full text-center">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="fixed inset-0 transition-opacity"
            onClick={hide}
          >
            <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
          </Transition.Child>

          <div
            className="relative flex-grow mx-4 sm:mx-6 mb-24 sm:mb-28 mt-4 sm:mt-6 flex items-center justify-center"
            onClick={(ev) => {
              if (ev.currentTarget === ev.target) {
                setIsOpen(false);
              }
            }}
          >
            {isOpen && !isLoaded && (
              <div className="absolute inset-0 flex items-center justify-center z-10 text-gray-100">
                <div className="loading-spinner-18">
                  <Spinner />
                </div>
              </div>
            )}
            <div
              className={classNames(
                'absolute rounded-xl w-full h-full text-left overflow-hidden shadow-xl transform transition-all ease-in-out duration-200',
                theme === 'dark' ? 'bg-gray-900' : 'bg-white',
                isOpen && isLoaded ? 'opacity-100 mt-0' : 'opacity-0 mt-80'
              )}
              style={{
                maxWidth: maxWidth === 'none' ? 'none' : `${maxWidth}px`,
                maxHeight: maxHeight === 'none' ? 'none' : `${maxHeight}px`,
              }}
              role="dialog"
              aria-modal="true"
            >
              {address && (
                <iframe
                  title="Scheduling Link"
                  src={address.href}
                  className="w-full h-full"
                  onLoad={() => setIsLoaded(true)}
                ></iframe>
              )}
            </div>
          </div>
        </div>
      </Transition>

      <Transition
        as="button"
        show={!isWidgetDelayed && (isWidgetEnabled || isOpen)}
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
        className={classNames(
          'fixed transform transition bottom-4 md:bottom-6 h-16',
          config.widget.position === 'bottom-left'
            ? 'left-4 md:left-10'
            : 'right-4 md:right-10',
          'text-base origin-center flex items-center rounded-full cursor-pointer transition-all duration-300 transform outline-none drop-shadow-md',
          'focus:outline-none focus:ring-4 focus:ring-gray-600 focus:ring-opacity-50',
          'active:scale-95',
          showWidgetPrompt ? 'py-4 px-6' : 'w-16 justify-center'
        )}
        style={{
          backgroundColor: config.widget.backgroundColor,
          color: config.widget.textColor,
          zIndex: zIndex + 1,
        }}
        onClick={() => {
          if (isOpen) return hide();

          const params: Partial<LinkParams> = {
            displayName: config.widget.displayName,
            email: config.widget.email,
            hideAvatar: config.widget.hideAvatar,
            hideBanner: config.widget.hideBanner,
            metadata: config.widget.metadata,
            phone: config.widget.phone,
            questions: config.widget.questions,
            showViewChanger: config.widget.showViewChanger,
            theme: config.widget.theme,
            view: config.widget.view,
          };

          if (config.widget.link) {
            setTheme(config.widget.theme);
            setIsOpen(true);
            updateAddress(
              buildFrameUrlFromPath(
                config.origin,
                config.widget.link,
                'popover',
                'popover',
                params
              )
            );
          }
        }}
      >
        {(isOpen || config.widget.icon !== 'none') && (
          <div
            className={classNames(
              'flex items-center justify-center',
              showWidgetPrompt ? 'mr-3' : ''
            )}
          >
            {isOpen || !isWidgetEnabled ? <XIcon /> : <CalendarIcon />}
          </div>
        )}
        {showWidgetPrompt && (
          <div className="font-semibold antialiased leading-5">
            {config.widget.prompt}
          </div>
        )}
      </Transition>
    </div>
  );
};
