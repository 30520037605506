$(function () {


    /*
    |--------------------------------------------------------------------------
    | Configure your website
    |--------------------------------------------------------------------------
    |
    | We provided several configuration variables for your ease of development.
    | Read their complete description and modify them based on your need.
    |
    */

    page.config({

        /*
        |--------------------------------------------------------------------------
        | Google API Key
        |--------------------------------------------------------------------------
        |
        | Here you may specify your Google API key if you need to use Google Maps
        | in your application
        |
        | https://developers.google.com/maps/documentation/javascript/get-api-key
        |
        */

        googleApiKey: '',

        /*
        |--------------------------------------------------------------------------
        | Google Analytics Tracking
        |--------------------------------------------------------------------------
        |
        | If you want to use Google Analytics, you can specify your Tracking ID in
        | this option. Your key would be a value like: UA-12345678-9
        |
        */

        googleAnalyticsId: '',

        /*
        |--------------------------------------------------------------------------
        | Google reCAPTCHA
        |--------------------------------------------------------------------------
        |
        | reCAPTCHA protects you against spam and other types of automated abuse.
        | Please signup for an API key pair and insert your `Site key` value to the
        | following variable.
        |
        | http://www.google.com/recaptcha/admin
        |
        */

        reCaptchaSiteKey: '6Ldaf0MUAAAAAHdsMv_7dND7BSTvdrE6VcQKpM-n',

        // See available languages: https://developers.google.com/recaptcha/docs/language
        reCaptchaLanguage: '',

        /*
        |--------------------------------------------------------------------------
        | Disable AOS on mobile
        |--------------------------------------------------------------------------
        |
        | If true, the Animate On Scroll animations don't run on mobile devices.
        |
        */

        disableAOSonMobile: true,

        /*
        |--------------------------------------------------------------------------
        | Smooth Scroll
        |--------------------------------------------------------------------------
        |
        | If true, the browser's scrollbar moves smoothly on scroll and gives your
        | visitor a better experience for scrolling.
        |
        */

        smoothScroll: true,

    });

});
