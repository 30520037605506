/*
|--------------------------------------------------------------------------
| Custom Javascript code
|--------------------------------------------------------------------------
|
| Now that you configured your website, you can write additional Javascript
| code inside the following function. You might want to add more plugins and
| initialize them in this file.
|
*/

$(function () {
    // Cookie message
    var cookie_name = 'cookies_accepted';
    if (typeof Cookies.get(cookie_name) === 'undefined') {
        // Show the cookie message
        $('#popup-cookie-display').click();

        // Mark assumed acceptance
        Cookies.set(cookie_name, 1, {expires: 365});

        // Record cookie acceptance on button click
        $('#cookie_acceptance').click(function () {
            Cookies.set(cookie_name, 2, {expires: 365});
        });
    }
});
